<template>
  <div class="home">
      <div class="common-layout">
        <el-container>
          <el-header class="home-header">
            <BuildTalentsHead></BuildTalentsHead>
          </el-header>
          <el-main class="home-main">
              <div>
                <van-nav-bar
                title= "职称申报服务"
                class="title-size"
                />
                <ul ref="scroll"
                    v-infinite-scroll="load"
                    class="infinite-list" 
                    infinite-scroll-immediate="false"
                    style="overflow: auto">
                        <div v-for="(item , index ) in jobTileList" :key="index">
                            <div class="chat-item-title" @click="goToDetail(item)">
                              {{ item.fileName }}
                            </div>
                        </div>
                    <div v-if="busy" class="loading">加载中...</div>
                </ul> 
              </div>
          </el-main>
          <el-footer>
            <div class="home-bottom">
              <BuildTalentsBottom></BuildTalentsBottom>
            </div>
          </el-footer>
        </el-container>
      </div>
  </div>
</template>

<script setup>
import BuildTalentsHead from '@/components/BuildTalentsHead.vue'
import BuildTalentsBottom from '@/components/BuildTalentsBottom.vue';
import { ref ,onMounted} from 'vue';
import { useRouter , useRoute } from 'vue-router';
import { ElMessage } from 'element-plus';
import axios from 'axios';
const jobTileList = ref([

 ])

 const busy = ref(false);
 const DEFAULT_PAGE_SIZE = 30;
 const DEFAULT_PAGE_NUM = 1;
 const DEFAULT_REFRSH_TIME=2000;
 const pageNum = ref(DEFAULT_PAGE_NUM);
//  是否全部数据加载完毕
 const isAllDataLoaded = ref(false);

 const load = () => {
    if (busy.value) return;
    busy.value = true;
    pageNum.value += 1;
    setTimeout(() => {
      busy.value = false;
    }, DEFAULT_REFRSH_TIME);
    initJobTilteList(pageNum.value,DEFAULT_PAGE_SIZE)
    }
   
const router = useRouter();
const route = useRoute();
const goToDetail = (item) => {
  router.push({
      path: '/pdfTest', 
      query: { 
        fileUrl: item.fileUrl,
        fileName: item.fileName,
       }
    });
}

const initJobTilteList = (pageNum,pageSize) => {
  if(isAllDataLoaded.value){
    ElMessage({
      message: '没有更多数据了',
      type: 'warning',
    });
    return;
  }
   const response = axios.get('/api/titleDocument/fileList',{
    params:{pageNum:pageNum,pageSize:pageSize}
  });
   response.then(res => {
      if (res.data.code === 200) {
        if (res.data.data.records.length === 0) {
          isAllDataLoaded.value = true;
          ElMessage({
            message: '没有更多数据了',
            type: 'warning'
          });
          return;
        }
        jobTileList.value.push(...res.data.data.records);
      }
   })
}
onMounted(() => {
  initJobTilteList(DEFAULT_PAGE_NUM,DEFAULT_PAGE_SIZE)
  
})

</script>

<style>
.common-layout{
  background-color: #fff;
}
.home-header{
  height: 30% !important;
  padding: 0% !important;
}
.home-main{
  height: 70% !important;
}
.menu-position-item {
  width: 90%; /* 设置宽度 */
  height: 50px; /* 设置高度 */
  float: left; /* 设置浮动 */
  margin: 5px; /* 设置标签之间的间隔 */
  margin-left: 10px;
  line-height: 30px; /* 垂直居中 */
  text-align: center; /* 水平居中 */
}
.el-main{
  --el-main-padding: 6px !important
}
.van-nav-bar__title{
  max-width: 45% !important;
  font-size: 24px !important;
}
.infinite-list {
height: 527px;
padding: 0;
margin: 0;
list-style: none;
/* background-color: beige; */
}

.chat-item-title{
  padding-top: 2px;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
  margin: auto;
  font-size: 15px;
}

.home-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff; /* 可以根据需要调整背景颜色 */
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); /* 可以根据需要调整阴影效果 */
  z-index: 1000; /* 确保 footer 在其他内容之上 */
}


.el-footer{
  height: 25px !important;
}


@media screen and (min-width: 500px) {
  .chat-item-title{
    padding-top: 2px;
    padding-left: 5px;
    padding-right: 5px;
    box-sizing: border-box;
    cursor: pointer; /* 设置鼠标悬停时的样式 */
    margin: auto;
    font-size: 20px;
  }
} 

</style>
